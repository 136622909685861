import { IAppService } from '../models/app'

export default [
  {
    name: 'Baita',
    appId: '2d12accb-4b7c-4d22-bdbc-4875a404b929',
    config: {},
    services: [
      {
        type: 'trigger',
        name: 'webhook',
        label: 'Webhook',
        config: {},
      },
      {
        type: 'trigger',
        name: 'schedule',
        label: 'Schedule',
        config: {
          inputFields: [
            {
              name: 'expression',
              label: 'Expression',
              type: 'options',
              required: true,
              options: [
                {
                  label: 'Run every 10 minutes',
                  value: 'rate(10 minutes)',
                },
                {
                  label: 'Run every 30 minutes',
                  value: 'rate(30 minutes)',
                },
                {
                  label: 'Run every hour',
                  value: 'rate(1 hour)',
                },
                {
                  label: 'Run every day at 9:00 am',
                  value: 'cron(0 9 * * ? *)',
                },
                {
                  label: 'Run every day at 8:00, 12:00, 16:00 and 20:00',
                  value: 'cron(0 8,12,16,20 * * ? *)',
                },
                {
                  label: 'Run every Monday through Friday at 9:00 am',
                  value: 'cron(0 9 ? * MON-FRI *)',
                },
                {
                  label:
                    'Run every hour Monday to Friday between 9:00 am and 5:00 pm',
                  value: 'cron(0 9-17 ? * MON-FRI *)',
                },
              ],
            },
            {
              name: 'timeZone',
              label: 'Time Zone',
              type: 'user',
              required: true,
            },
          ],
        },
      },
      {
        type: 'invoke',
        name: 'code-execute',
        label: 'Run Javascript',
        config: {
          customFields: true,
          inputFields: [
            {
              type: 'code',
              name: 'code',
              label: 'Code',
              description: `1. Insert your Javascript code here.\n2. Access your variables with the same name as you declared them.\n3. The output will be what is attributed to "output" variable`,
              value: `const id = '123';\nconst title = 'abc';\n\n\n\n\n\n\n\noutput = { id, title };`,
              required: true,
            },
          ],
        },
      },
      {
        type: 'invoke',
        name: 'method-execute',
        label: 'Get my "To Do" list',
        config: {
          method: 'getTodo',
        },
      },
      {
        type: 'invoke',
        name: 'method-execute',
        label: 'Send me a notification',
        config: {
          method: 'sendNotification',
          inputFields: [
            {
              name: 'token',
              label: 'Token',
              type: 'user',
              required: true,
            },
            {
              name: 'notification.title',
              label: 'Title',
              required: true,
              type: 'output',
            },
            {
              name: 'notification.body',
              label: 'Body',
              type: 'output',
            },
            {
              name: 'notification.icon',
              label: 'Icon',
              type: 'output',
            },
            {
              name: 'notification.image',
              label: 'Image',
              type: 'output',
            },
            {
              name: 'notification.timestamp',
              label: 'Timestamp',
              type: 'output',
            },
            {
              name: 'notification.actions',
              label: 'Actions',
              type: 'output',
            },
            {
              name: 'url',
              label: 'On click Url',
              type: 'output',
            },
          ],
        },
      },
      {
        type: 'invoke',
        name: 'method-execute',
        label: 'Publish content to feed',
        config: {
          method: 'publishToFeed',
          inputFields: [
            {
              type: 'output',
              name: 'content',
              label: 'content',
              required: true,
            },
          ],
        },
      },
    ],
  },
  {
    name: 'NewsAPI',
    appId: 'f9686ea8-ac80-4f70-b35b-asd',
    config: {
      apiUrl: 'https://newsapi.org/v2',
    },
    services: [
      {
        type: 'invoke',
        name: 'http-request',
        label: 'Get news',
        config: {
          method: 'get',
          inputFields: [
            {
              name: 'path',
              label: 'Path',
              type: 'constant',
              value: 'top-headlines',
            },
            {
              name: 'queryParams.country',
              label: 'Country',
              type: 'options',
              options: [
                {
                  label: 'United States',
                  value: 'us',
                },
                {
                  label: 'Brazil',
                  value: 'br',
                },
                {
                  label: 'Netherlands',
                  value: 'nl',
                },
              ],
            },
            {
              name: 'queryParams.q',
              label: 'Key words',
              type: 'output',
            },
            {
              name: 'queryParams.apiKey',
              label: 'Api key',
              type: 'environment',
              value: 'NEWS_API_KEY',
            },
            {
              name: 'queryParams.pageSize',
              label: 'Page size',
              type: 'constant',
              value: '30',
            },
          ],
          outputPath: 'articles',
          outputMapping: {
            source: '###NewsAPI',
            contentId: 'publishedAt',
            header: 'title',
            body: 'description',
            image: 'urlToImage',
            date: 'publishedAt',
            url: 'url',
            'author.name': 'source.name',
          },
        },
      },
    ],
  },
  {
    name: 'ChatGPT',
    appId: 'f9686ea8-ac80-4f70-b35b-hggfhjhk',
    config: {
      apiUrl: 'https://api.openai.com/v1',
    },
    services: [
      {
        type: 'invoke',
        name: 'http-request',
        label: 'Get Text Completion',
        config: {
          method: 'post',
          inputFields: [
            {
              name: 'path',
              label: 'Path',
              type: 'constant',
              value: 'completions',
            },
            {
              name: 'headers.Authorization',
              label: 'Authorization',
              type: 'environment',
              value: 'OPENAI_API_AUTHORIZATION',
            },
            {
              name: 'bodyParams.prompt',
              label: 'Ask something to ChatGPT',
              required: true,
              type: 'output',
            },
            {
              name: 'bodyParams.model',
              label: 'Model',
              required: true,
              value: 'text-davinci-003',
              type: 'constant',
            },
            {
              name: 'bodyParams.temperature',
              label: 'Temperature',
              required: true,
              value: 0.9,
              type: 'constant',
            },
            {
              name: 'bodyParams.max_tokens',
              label: 'Max tokens',
              required: true,
              value: 100,
              type: 'constant',
            },
          ],
          outputPath: 'choices.0.text',
        },
      },
      {
        type: 'invoke',
        name: 'http-request',
        label: 'Create an image',
        config: {
          method: 'post',
          inputFields: [
            {
              name: 'path',
              label: 'Path',
              type: 'constant',
              value: 'images/generations',
            },
            {
              name: 'headers.Authorization',
              label: 'Authorization',
              type: 'environment',
              value: 'OPENAI_API_AUTHORIZATION',
            },
            {
              name: 'bodyParams.prompt',
              label: 'What kind of image?',
              required: true,
              type: 'output',
            },
            {
              name: 'bodyParams.size',
              label: 'Size',
              required: true,
              value: '256x256',
              type: 'constant',
            },
          ],
          outputPath: 'data.0.url',
        },
      },
    ],
  },
  {
    name: 'ReceitaWS',
    appId: 'f9686ea8-ac80-4f70-b35b-8aa6da2c10a3',
    config: {
      apiUrl: 'https://www.receitaws.com.br/v1',
    },
    services: [
      {
        type: 'invoke',
        name: 'http-request',
        label: 'Search company',
        config: {
          method: 'get',
          inputFields: [
            {
              name: 'path',
              label: 'Path',
              type: 'constant',
              value: 'cnpj',
            },
            {
              name: 'urlParams.cnpj',
              label: 'CNPJ',
              type: 'output',
              required: true,
            },
          ],
        },
      },
    ],
  },
  {
    name: 'Pipedrive',
    appId: '19c1921c-9a6b-4def-91c8-8bcba8239bf5',
    config: {
      apiUrl: 'https://api.pipedrive.com/v1',
      authorizeUrl:
        'https://oauth.pipedrive.com/oauth/authorize?client_id=987a469172b3ac62&redirect_uri=https://api.baita.help/connectors/pipedrive&state=',
      auth: {
        type: 'basic',
        method: 'post',
        url: 'https://oauth.pipedrive.com/oauth/token',
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
        fields: {
          username: 'PIPEDRIVE_CLIENT_ID',
          password: 'PIPEDRIVE_CLIENT_SECRET',
        },
      },
    },
    services: [
      {
        type: 'invoke',
        name: 'oauth2-request',
        label: 'Search person',
        config: {
          method: 'get',
          inputFields: [
            {
              name: 'path',
              label: 'Path',
              type: 'constant',
              value: 'persons/search',
            },
            {
              name: 'queryParams.fieldName',
              label: 'search field name',
              type: 'options',
              required: true,
              options: [
                {
                  label: 'Name',
                  value: 'name',
                },
                {
                  label: 'E-mail',
                  value: 'email',
                },
                {
                  label: 'Phone',
                  value: 'phone',
                },
                {
                  label: 'Notes',
                  value: 'notes',
                },
                {
                  label: 'Custom fields',
                  value: 'customFields',
                },
              ],
            },
            {
              name: 'queryParams.term',
              label: 'Search term',
              type: 'output',
              required: true,
            },
            {
              name: 'queryParams.start',
              label: 'Start page',
              type: 'constant',
              value: '0',
            },
          ],
          outputPath: 'data.items.0.item',
        },
      },
      {
        type: 'invoke',
        name: 'oauth2-request',
        label: 'Search deal',
        config: {
          method: 'get',
          inputFields: [
            {
              name: 'path',
              label: 'Path',
              type: 'constant',
              value: 'deals/search',
            },
            {
              name: 'queryParams.fieldName',
              label: 'search field name',
              type: 'options',
              required: true,
              options: [
                {
                  label: 'Title',
                  value: 'title',
                },
                {
                  label: 'Notes',
                  value: 'notes',
                },
                {
                  label: 'Custom fields',
                  value: 'customFields',
                },
              ],
            },
            {
              name: 'queryParams.term',
              label: 'Search term',
              type: 'output',
              required: true,
            },
            {
              name: 'queryParams.start',
              label: 'Start page',
              type: 'constant',
              value: '0',
            },
          ],
          outputPath: 'data.items.0.item',
        },
      },
    ],
  },
  {
    name: 'GMail',
    appId: '5c16e311-a65a-449c-ad82-1f23a41cf89c',
    config: {
      apiUrl: 'https://gmail.googleapis.com',
      authorizeUrl:
        'https://accounts.google.com/o/oauth2/auth?client_id=106617044495-k3n0koedh38faoclgjqdss7vptfoirjr.apps.googleusercontent.com&redirect_uri=https://api.baita.help/connectors/google&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email+https://mail.google.com/+https://www.googleapis.com/auth/pubsub&state=',
      auth: {
        type: 'body',
        method: 'post',
        url: 'https://accounts.google.com/o/oauth2/token',
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
        fields: {
          username: 'GOOGLE_CLIENT_ID',
          password: 'GOOGLE_CLIENT_SECRET',
        },
      },
    },
    services: [
      {
        type: 'invoke',
        name: 'oauth2-request',
        label: 'Get profile',
        config: {
          method: 'get',
          inputFields: [
            {
              name: 'path',
              label: 'Path',
              type: 'constant',
              value: 'gmail/v1/users',
            },
            {
              name: 'urlParams.profile',
              label: 'Profile',
              type: 'constant',
              value: 'profile',
              required: true,
            },
          ],
        },
      },
    ],
  },
] as IAppService[]
