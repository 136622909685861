import React, { useContext, useEffect } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import AddIcon from '@mui/icons-material/Add'
import { NotificationContext } from '../../../../providers/notification'
import { IVariable, VariableType } from '../../../../models/service'
import appConfig, { Labels } from '../../../../utils/config'
import { getPushToken } from '../../../../utils/firebase'
import { Button, Text } from '../../../../components'

const PushNotificationService: React.FC<{
  inputData: IVariable[]
  updateBotInputField: (fieldName: string, inputField: IVariable) => void
}> = ({ inputData, updateBotInputField }) => {
  const [token, setToken] = React.useState<string>()
  const { showSnack } = useContext(NotificationContext)

  const retrieveToken = () => {
    getPushToken().then((token) => {
      if (token) {
        setToken(token)
        showSnack('Notifications allowed', 'success')
        updateBotInputField('token', {
          type: VariableType.constant,
          name: 'token',
          label: 'Token',
          value: token,
          sampleValue: token,
        })
      }
    })
  }

  useEffect(() => {
    const tokenField = inputData.find((x) => x.name === 'token')
    if (tokenField?.value) {
      setToken(tokenField?.value as string)
    }
  }, [])

  return (
    <div className="d-flex justify-content-center mt-3 mx-3">
      {token ? (
        <>
          <CheckIcon color="success" />
          <Text className="mx-2">{labels.tokenSaved}</Text>
        </>
      ) : (
        <Button
          type="text"
          color="primary"
          icon={<AddIcon />}
          onClick={retrieveToken}
        >
          {labels.permissionButton}
        </Button>
      )}
    </div>
  )
}

export default PushNotificationService

const LABELS: Labels = {
  en: {
    tokenSaved: 'Token saved',
    permissionButton: 'Allow notifications',
  },
  pt: {
    tokenSaved: 'Token salvo',
    permissionButton: 'Permitir notificações',
  },
}

const labels = LABELS[appConfig.language]
