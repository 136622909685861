//////////////////////////////////////
// Config models

enum Environment {
  local = 'localhost',
  production = 'www.baita.help',
}

enum Language {
  'en-US' = 'en',
  'pt-BR' = 'pt',
}

//////////////////////////////////////
// Default values

const defaultLanguage = Language['en-US']
const remoteApiUrl = 'https://api.baita.help'
const defaultLocalApi: 'remote' | 'mock' | 'local' = 'remote'

//////////////////////////////////////
// Configs
interface EnvConfig {
  isProduction: boolean
  apiUrl: string
}

interface AppConfig extends EnvConfig {
  language: Language
}

export interface Labels {
  [key: string]: { [key: string]: string }
}

export const configMapping: { [key in Environment]: EnvConfig } = {
  [Environment.local]: {
    isProduction: false,
    apiUrl:
      defaultLocalApi == 'remote'
        ? remoteApiUrl
        : defaultLocalApi == 'mock'
        ? 'http://localhost:3000'
        : 'http://localhost:5000/dev',
  },
  [Environment.production]: {
    isProduction: true,
    apiUrl: remoteApiUrl,
  },
}

const appConfig: AppConfig = {
  language:
    Language[navigator.language as keyof typeof Language] || defaultLanguage,
  ...configMapping[window.location.hostname as Environment],
}

export default appConfig
