import { IApp } from "./app"

export enum ServiceType {
  invoke = 'invoke',
  trigger = 'trigger',
}

export enum ServiceName {
  code = 'code-execute',
  http = 'http-request',
  queue = 'queue-publish',
  oauth2 = 'oauth2-request',
  webhook = 'webhook',
  schedule = 'schedule',
}

export enum InputSource {
  // auth = 'authFields',
  input = 'inputFields',
  value = 'valueFields',
  service = 'serviceFields',
}

export enum VariableType {
  code = 'code',
  user = 'user',
  text = 'text',
  output = 'output',
  options = 'options',
  boolean = 'boolean',
  constant = 'constant',
  environment = 'environment',
}

export type DataType =
  | null
  | string
  | number
  | boolean
  | object
  | Array<string | number | boolean | object>

export interface IVariable {
  type: VariableType
  name: string
  label: string
  value?: DataType
  sampleValue?: DataType
  description?: string
  required?: boolean
  outputIndex?: number
  outputPath?: string
  customFieldId?: number
  groupName?: string
  options?: {
    label: string
    value: string
  }[]
}

export interface IServiceConfig {
  path?: string
  method?: string
  customFields?: boolean
  inputFields?: IVariable[]
  outputPath?: string
  outputMapping?: {
    [key: string]: string
  }
  queryParams?: {
    paramName: string
    source: InputSource
    fieldName?: string
    value?: string | number
  }[]
  bodyParams?: {
    paramName: string
    source: InputSource
    fieldName?: string
    value?: string | number
  }[]
  urlParams?: {
    source: InputSource
    fieldName?: string
    value?: string | number
  }[]
}

export interface IService {
  type: ServiceType
  name: ServiceName
  label: string
  config: IServiceConfig
}

export interface IServiceApp {
  service: IService
  app: IApp
}