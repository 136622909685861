import { useContext } from 'react'
import Axios from 'axios'
import {
  IBot,
  IBotLog,
  IBotModel,
  ITask,
  ITaskExecutionResult,
} from '../models/bot'
import { IAppService } from '../models/app'
import { IAppConnection } from '../models/connection'
import { IContent, ITodoTask } from '../models/user'
import { AuthContext } from '../providers/auth'
import apps from '../defines/apps'
import appConfig from './config'

const ApiRequest = () => {
  const { user, getToken } = useContext(AuthContext)

  const apiClient = Axios.create({
    baseURL: `${appConfig.apiUrl}/user/${user?.userId}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })

  const getApiResponse = <T>(method: string, url: string, data?: any) => {
    return new Promise<T>((resolve, reject) => {
      apiClient
        .request({ method, url, data })
        .then((response) => {
          if (!response.data) reject(response)
          if (!response.data.success)
            reject({
              message: response.data.message,
              stack: 'utils.requests',
              cause: response,
            })
          resolve(response.data.data)
        })
        .catch(reject)
    })
  }

  const getBotModels = () => {
    return getApiResponse<IBotModel[]>('get', 'models')
  }

  const publishBotModel = (model: IBotModel) => {
    return getApiResponse<IBotModel>('post', `models`, model)
  }

  const deleteBotModel = (modelId: string) => {
    return getApiResponse<void>('delete', `models/${modelId}`)
  }

  const createBot = () => {
    return getApiResponse<IBot>('post', 'bots')
  }

  const deployBotModel = (modelId: string, model: IBotModel) => {
    return getApiResponse<IBot>('post', `bots/${modelId}/bud`, model)
  }

  const getBots = () => {
    return getApiResponse<IBot[]>('get', 'bots')
  }

  const getContent = () => {
    return getApiResponse<IContent[]>('get', 'content')
  }

  const reactToContent = (content: IContent, reaction: string) => {
    return getApiResponse<void>(
      'post',
      `content/${content.contentId}/react/${reaction}`,
      content
    )
  }

  const getTodo = () => {
    return getApiResponse<ITodoTask[]>('get', 'todo')
  }

  const updateTodo = (tasks: ITodoTask[]) => {
    return getApiResponse<ITodoTask[]>('put', 'todo', tasks)
  }

  const getAppConnections = () => {
    return getApiResponse<IAppConnection[]>('get', `connections`)
  }

  const getBot = (botId: string) => {
    return getApiResponse<IBot>('get', `bots/${botId}`)
  }

  const updateBot = (botId: string, bot: Partial<IBot>) => {
    return getApiResponse<IBot>('put', `bots/${botId}`, bot)
  }

  const deleteBot = (botId: string, apiId: string) => {
    return getApiResponse<void>('delete', `bots/${botId}/api/${apiId}`)
  }

  const deployBot = (botId: string, bot: Partial<IBot>) => {
    return getApiResponse<IBot>('post', `bots/${botId}/deploy`, bot)
  }

  const testBot = (botId: string, task: ITask, taskIndex: number) => {
    return getApiResponse<ITaskExecutionResult>(
      'post',
      `bots/${botId}/test/${taskIndex}`,
      task
    )
  }

  const getLogs = (botId: string) => {
    return getApiResponse<IBotLog[]>('get', `bots/${botId}/logs`)
  }

  const getApps = () => {
    return new Promise<IAppService[]>((resolve) => resolve(apps))
  }

  return {
    getBot,
    getBots,
    getApps,
    getLogs,
    testBot,
    getTodo,
    createBot,
    deployBot,
    deleteBot,
    updateBot,
    updateTodo,
    getContent,
    getBotModels,
    reactToContent,
    deployBotModel,
    deleteBotModel,
    publishBotModel,
    getAppConnections,
  }
}

export default ApiRequest
