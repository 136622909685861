import React, { useContext, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ServiceName, ServiceType } from '../../../models/service'
import appConfig, { Labels } from '../../../utils/config'
import { BotContext } from '../../../providers/bot'
import { Button, Text } from '../../../components'
import { ITask } from '../../../models/bot'
import TaskService from './service'
import TaskOptions from './options'
import TaskInput from './input'
import TaskTest from './test'

const Task: React.FC<{
  taskIndex: number
}> = ({ taskIndex }) => {
  const { bot, updateBot } = useContext(BotContext)

  const [task, setTask] = useState<ITask>()
  const [openedPanel, setOpenedPanel] = useState<string>()

  const onPanelChange =
    (panel: string) =>
    (_: React.SyntheticEvent<Element, Event>, isExpanded: boolean) => {
      setOpenedPanel(isExpanded ? panel : undefined)
    }

  const addTask = (taskIndex: number) => {
    if (bot) {
      bot.tasks.splice(taskIndex + 1, 0, {
        taskId: Date.now(),
        inputData: [],
      })
      updateBot({ ...bot })
    }
  }

  const deleteTask = (taskId: number) => {
    if (bot) {
      const updatedBot = {
        ...bot,
        tasks: bot.tasks.filter((x) => x.taskId !== taskId),
      }
      updateBot(updatedBot)
    }
  }

  useEffect(() => {
    if (bot) {
      setTask(bot.tasks[taskIndex])
    }
  }, [bot])

  return (
    <div className="mt-4">
      {bot && task && (
        <>
          <div className="d-flex justify-content-between">
            {/***** Task Title *****/}
            {taskIndex === 0 ? (
              <Text className="mx-2 text-primary fw-bold">
                {labels.triggerTitle}
              </Text>
            ) : (
              <div className="d-flex w-100 justify-content-between">
                <div className="d-flex">
                  <Button
                    iconButton
                    onClick={() => deleteTask(task.taskId)}
                    icon={
                      <DeleteIcon
                        className="fs-3 bg-light p-1"
                        style={{ borderRadius: 4 }}
                      />
                    }
                  />
                  <div className="d-flex align-items-center mx-1">
                    <Text className="text-primary fs-4 fw-bold">
                      {taskIndex}
                    </Text>
                    <Text
                      className=" text-primary fw-bold mx-1"
                      style={{ fontSize: 14 }}
                    >
                      {task.service ? task.service.label : '...'}
                    </Text>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/***** Service *****/}
          <Accordion
            className="mt-2"
            expanded={openedPanel === `${taskIndex}-s`}
            onChange={onPanelChange(`${taskIndex}-s`)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {labels.serviceTitle}
            </AccordionSummary>
            <AccordionDetails>
              <TaskService taskIndex={taskIndex} />
            </AccordionDetails>
          </Accordion>

          {/***** Show only after selecting a service that does not require a connection,
           ****** or after selecting/creating a connectionId *****/}
          {task.service &&
            (!task.app?.config?.auth?.url || task.connectionId) && (
              <>
                {/***** Input *****/}
                {(task.service.config.inputFields ||
                  task.service.config.customFields) && (
                  <Accordion
                    expanded={openedPanel === `${taskIndex}-i`}
                    onChange={onPanelChange(`${taskIndex}-i`)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      {labels.inputDataTitle}
                    </AccordionSummary>
                    <AccordionDetails className="d-block">
                      <TaskInput taskIndex={taskIndex} />
                    </AccordionDetails>
                  </Accordion>
                )}

                {/***** Options *****/}
                {/* TODO correct condictions logic on backend first
                {task.service.type !== ServiceType.trigger && (
                  <Accordion
                    expanded={openedPanel === `${taskIndex}-o`}
                    onChange={onPanelChange(`${taskIndex}-o`)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      {labels.optionsTitle}
                    </AccordionSummary>
                    <AccordionDetails className="d-block">
                      <TaskOptions taskIndex={taskIndex} />
                    </AccordionDetails>
                  </Accordion>
                )} */}

                {/***** Test *****/}
                {(task.service.type !== ServiceType.trigger ||
                  task.service.name !== ServiceName.schedule) && (
                  <Accordion
                    expanded={openedPanel === `${taskIndex}-t`}
                    onChange={onPanelChange(`${taskIndex}-t`)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      {labels.testTitle}
                    </AccordionSummary>
                    <AccordionDetails className="d-block">
                      <TaskTest taskIndex={taskIndex} />
                    </AccordionDetails>
                  </Accordion>
                )}
              </>
            )}

          {/***** Bottom buttons tab *****/}
          <div className="d-flex justify-content-center mt-4">
            <Button
              size="small"
              icon={<AddIcon />}
              onClick={() => addTask(taskIndex)}
            >
              {labels.addTask}
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default Task

const LABELS: Labels = {
  en: {
    triggerTitle: 'Trigger',
    serviceTitle: 'Service',
    inputDataTitle: 'Input Data',
    addTask: 'Add Task',
    optionsTitle: 'Options',
    testTitle: 'Tests',
  },
  pt: {
    triggerTitle: 'Gatilho',
    serviceTitle: 'Serviço',
    inputDataTitle: 'Dados de entrada',
    addTask: 'Addicionar Tarefa',
    optionsTitle: 'Opções',
    testTitle: 'Testes',
  },
}

const labels = LABELS[appConfig.language]
