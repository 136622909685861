import React, { createContext, useState } from 'react'
import { IBot, IBotModel, ITask } from '../models/bot'
import { IVariable, ServiceType, VariableType } from '../models/service'
import { getPushToken } from '../utils/firebase'
import ApiRequest from '../utils/requests'

export const BotContext = createContext<{
  bots: IBot[] | undefined
  getBots: () => Promise<void>
  bot: IBot | undefined
  setBot: (bot?: IBot) => void
  getBot: (botId: string) => Promise<void>
  createBot: () => Promise<string>
  deleteBotModel: (modelId: string) => Promise<void>
  deployBotModel: (botId: string, model: IBotModel) => Promise<IBot>
  deleteBot: (botId: string, apiId: string) => Promise<void>
  getBotInputs: (tasks: ITask[]) => IVariable[]
  updateBot: (bot: IBot) => Promise<void>
  deployBot: (bot: IBot) => Promise<IBot>
  testBotTask: (bot: IBot, taskIndex: number) => Promise<void>
  updateBotTask: (
    botId: string,
    taskIndex: number,
    task: ITask,
    save?: boolean
  ) => Promise<void>
}>({
  bots: undefined,
  getBots: () => Promise.resolve(),
  bot: undefined,
  setBot: () => undefined,
  getBot: () => Promise.resolve(),
  createBot: () => Promise.resolve(''),
  deleteBotModel: () => Promise.resolve(),
  deployBotModel: () => Promise.resolve({} as IBot),
  deleteBot: () => Promise.resolve(),
  getBotInputs: () => [],
  updateBot: () => Promise.resolve(),
  deployBot: () => Promise.resolve({} as IBot),
  testBotTask: () => Promise.resolve(),
  updateBotTask: () => Promise.resolve(),
})

const BotProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const apiRequest = ApiRequest()
  const [bot, setBot] = useState<IBot>()
  const [bots, setBots] = useState<IBot[]>()

  const createBot = () => {
    return apiRequest.createBot().then((bot) => {
      setBot(bot)
      return bot.botId
    })
  }

  const deployBotModel = (botId: string, model: IBotModel) => {
    return parseUserInputs(model.tasks).then((tasks) =>
      apiRequest.deployBotModel(botId, { ...model, tasks }).then((bot) => {
        getBots()
        return bot
      })
    )
  }

  const deleteBotModel = (modelId: string) => {
    return apiRequest.deleteBotModel(modelId).then((bot) => {
      getBots()
      return bot
    })
  }

  const deleteBot = (botId: string, apiId: string) => {
    setBots(bots?.filter((bot) => bot.botId !== botId))
    return apiRequest.deleteBot(botId, apiId).then(() => {
      return
    })
  }

  const getBot = (botId: string) => {
    return apiRequest.getBot(botId).then((bot) => {
      setBot(bot)
      return
    })
  }

  const getBots = () => {
    return apiRequest.getBots().then((bots) => {
      setBots(bots)
      return
    })
  }

  const updateBot = (bot: IBot) => {
    setBot(bot)
    return apiRequest.updateBot(bot.botId, bot).then(() => {
      return
    })
  }

  const deployBot = (bot: IBot) => {
    return parseUserInputs(bot.tasks).then((tasks) =>
      apiRequest.deployBot(bot.botId, { ...bot, tasks }).then((bot) => {
        setBot(bot)
        getBots()
        return bot
      })
    )
  }

  const testBotTask = (bot: IBot, taskIndex: number) => {
    return apiRequest.updateBot(bot.botId, bot).then(() =>
      apiRequest.testBot(bot.botId, bot.tasks[taskIndex], taskIndex).then(() =>
        apiRequest.getBot(bot.botId).then((bot) => {
          setBot(bot)
          return
        })
      )
    )
  }

  const updateBotTask = (
    botId: string,
    taskIndex: number,
    task: ITask,
    save: boolean = true
  ) => {
    const updatedBot = {
      ...bot,
      tasks: bot?.tasks.map((t, i) => (i !== taskIndex ? t : task)),
    } as IBot
    setBot(updatedBot)
    return !save
      ? Promise.resolve()
      : apiRequest.updateBot(botId, updatedBot).then(() => {
          return
        })
  }

  const getBotInputs = (tasks: ITask[]) => {
    const inputs: IVariable[] = []

    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].sampleResult && tasks[i].sampleResult?.outputData) {
        const taskIndex = i
        const groupName =
          taskIndex === 0 ? ServiceType.trigger : `task ${taskIndex}`
        const outputData = tasks[taskIndex].sampleResult?.outputData

        const getFromVariable = (name: string, value: any) => {
          inputs.push({
            name,
            value,
            type: VariableType.output,
            label: `${name}: ${value}`,
            sampleValue: null,
            groupName: groupName,
            outputIndex: taskIndex,
            outputPath: name,
          })
        }

        const getFromArray = (name: string, array: any) => {
          inputs.push({
            name: name,
            value: array,
            type: VariableType.output,
            label: `${name ? `${groupName}.${name}` : groupName}: [ ... ]`,
            sampleValue: null,
            groupName: groupName,
            outputIndex: taskIndex,
            outputPath: name,
          })
          for (let i = 0; i < array.length; i++) {
            const updatedName = name ? `${name}.${i}` : i.toString()
            const value = array[i]
            getInput(updatedName, value)
          }
        }

        const getFromObject = (name: string, object: any) => {
          const array = Object.keys(object)
          inputs.push({
            name: name,
            value: object,
            type: VariableType.output,
            label: `${name ? `${groupName}.${name}` : groupName}: { ... }`,
            sampleValue: null,
            groupName: groupName,
            outputIndex: taskIndex,
            outputPath: name,
          })
          for (let i = 0; i < array.length; i++) {
            const key = array[i]
            const updatedName = name ? `${name}.${key}` : key
            const value = object[key]
            getInput(updatedName, value)
          }
        }

        const getInput = (name: string, value: any) => {
          if (!value) return
          if (typeof value === 'string' || typeof value === 'number')
            getFromVariable(name, value)
          else if (Array.isArray(value)) getFromArray(name, value)
          else if (typeof value === 'object') getFromObject(name, value)
        }

        getInput('', outputData)
      }
    }

    return inputs
  }

  return (
    <BotContext.Provider
      value={{
        bots,
        getBots,
        bot,
        getBot,
        createBot,
        deployBotModel,
        deleteBotModel,
        deleteBot,
        updateBot,
        deployBot,
        testBotTask,
        updateBotTask,
        getBotInputs,
        setBot: (bot) => setBot(bot),
      }}
    >
      {children}
    </BotContext.Provider>
  )
}

export default BotProvider

const parseUserInputs = async (tasks: ITask[]) => {
  const serviceTasks = tasks.map((task) => ({
    taskId: task.taskId,
    fields: task.service?.config?.inputFields || [],
  }))

  let updatedTokenField: { taskId: number; field: IVariable }
  let updatedTimeZoneField: { taskId: number; field: IVariable }
  for (const task of serviceTasks) {
    for (const field of task.fields) {
      if (field.label === 'Token') {
        const token = await getPushToken()
        updatedTokenField = {
          taskId: task.taskId,
          field: { ...field, value: token },
        }
      } else if (field.label === 'Time Zone') {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
        updatedTimeZoneField = {
          taskId: task.taskId,
          field: { ...field, value: timeZone },
        }
      }
    }
  }

  return tasks.map((task) => ({
    ...task,
    inputData: [
      ...task.inputData.map((field) => {
        if (updatedTokenField && field.label === 'Token') {
          return updatedTokenField.field
        } else if (updatedTimeZoneField && field.label === 'Time Zone') {
          return updatedTimeZoneField.field
        }

        return field
      }),
      ...(updatedTokenField &&
      task.taskId === updatedTokenField.taskId &&
      !task.inputData.some((field) => field.label === 'Token')
        ? [updatedTokenField.field]
        : []),
      ...(updatedTimeZoneField &&
      task.taskId === updatedTimeZoneField.taskId &&
      !task.inputData.some((field) => field.label === 'Time Zone')
        ? [updatedTimeZoneField.field]
        : []),
    ],
  }))
}
